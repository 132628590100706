import { z } from 'zod';

export const cancellationPolicyContentSchema = z.object({
  refundable: z.string(),
  restrictive: z.boolean(),
  freeCancellationBefore: z.string(),
  nonRefundableFrom: z.string(),
  remarks: z.string(),
  penalties: z.array(
    z.object({
      percentage: z.coerce.string(),
      to: z.string(),
      from: z.string(),
    }),
  ),
});

export type CancellationPolicyContent = z.infer<
  typeof cancellationPolicyContentSchema
>;
