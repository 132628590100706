import { z } from 'zod';

export function isTruthyPredicate<SomeType>(
  item: SomeType | boolean | null | undefined,
): item is SomeType {
  return !!item;
}

/**
 * @deprecated Use `@/app/(types)/page-props` instead.
 */
export type PageProps = {
  params: Record<string, string>;
  searchParams?: Record<string, string>;
};

export function typedObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Array<keyof T>;
}

/**
 * A utility function that checks if a given property key exists in an object.
 * Needed for type narrowing when iterating via Object.keys.
 *
 * @template T - The type of the object being checked.
 *
 * @param {T} x - The object in which to check for the property key.
 * @param {PropertyKey} k - The key to check for in the object. This can be a string, number, or symbol.
 *
 * @returns {k is keyof T} - Returns true if the key exists in the object; otherwise, false.
 *
 * @see {@link https://www.totaltypescript.com/iterate-over-object-keys-in-typescript#solution-2-type-predicates Solution 2: Type Predicates}
 */
export function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
  return k in x;
}

/**
 * Creates a Zod schema for an object with keys representing each day of the week.
 *
 * @param schema - A Zod schema type to be used as the value type for each day of the week.
 * @returns A Zod object schema where each weekday (from Monday to Sunday) is validated
 *          using the provided schema.
 *
 * Example usage:
 * const numberSchema = z.number();
 * const weekdayNumberSchema = weekdaySchema(numberSchema);
 *
 */
export function weekdaySchema<T>(schema: z.ZodType<T>) {
  return z.object({
    friday: schema,
    monday: schema,
    saturday: schema,
    sunday: schema,
    thursday: schema,
    tuesday: schema,
    wednesday: schema,
  });
}
