import { travelBookingTransactionErrorSchema } from '@/schema/travel/travel-booking-transaction-error.schema';
import { z } from 'zod';

const searchSchema = z.object({
  adults: z.string(),
  base: z.string(),
  checkin: z.string(),
  checkout: z.string(),
  closedUserGroup: z.string(),
  currency: z.string(),
  destination: z.string(),
  guests: z.string(),
  hotelId: z.string(),
  lang: z.string(),
  nationality: z.string(),
  requestBody: z
    .object({
      billingTerms: z.string(),
      checkin: z.string(),
      checkout: z.string(),
      countryCode: z.string(),
      currency: z.string(),
      language: z.string(),
      occupancy: z.array(z.string()),
      partnerPointOfSale: z.string(),
      paymentTerms: z.number(),
      propertyId: z.array(z.string()).optional(),
      rateOption: z.string(),
      ratePlanCount: z.number(),
      salesChannel: z.string(),
      salesEnvironment: z.string(),
      sortType: z.string(),
    })
    .nullish(),
  requestHeaders: z
    .object({
      accept: z.string(),
      'accept-Encoding': z.string(),
      authorization: z.string(),
      'customer-Ip': z.string(),
      'user-Agent': z.string(),
    })
    .nullish(),
  response: z.object({
    links: z
      .object({
        recommendations: z.object({ href: z.string(), method: z.string() }),
      })
      .nullish(),
    propertyId: z.string().optional(),
    rooms: z
      .array(
        z.object({
          id: z.string(),
          rates: z.array(
            z.object({
              amenities: z.record(
                z.object({
                  id: z.string(),
                  name: z.string(),
                }),
              ),
              availableRooms: z.number(),
              bedGroups: z.record(
                z.object({
                  configuration: z.array(
                    z.object({
                      quantity: z.number(),
                      size: z.string(),
                      type: z.string(),
                    }),
                  ),
                  description: z.string(),
                  id: z.string(),
                  links: z.object({
                    priceCheck: z.object({
                      href: z.string(),
                      method: z.string(),
                    }),
                  }),
                }),
              ),
              cancelPenalties: z
                .array(
                  z.object({
                    currency: z.string(),
                    end: z.string(),
                    percent: z.string(),
                    start: z.string(),
                  }),
                )
                .optional(),
              id: z.string(),
              links: z.object({
                paymentOptions: z.object({
                  href: z.string(),
                  method: z.string(),
                }),
              }),
              memberDealAvailable: z.boolean(),
              merchantOfRecord: z.string(),
              occupancyPricing: z.record(
                z.object({
                  nightly: z.array(
                    z.array(
                      z.object({
                        currency: z.string(),
                        type: z.string(),
                        value: z.string(),
                      }),
                    ),
                  ),
                  totals: z.object({
                    exclusive: z.object({
                      billableCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                      requestCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                    }),
                    inclusive: z.object({
                      billableCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                      requestCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                    }),
                    inclusiveStrikethrough: z.object({
                      billableCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                      requestCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                    }),
                    strikethrough: z.object({
                      billableCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                      requestCurrency: z.object({
                        currency: z.string(),
                        value: z.string(),
                      }),
                    }),
                  }),
                }),
              ),
              promotions: z.object({
                deal: z.object({
                  description: z.string(),
                  id: z.string(),
                }),
              }),
              refundable: z.boolean(),
              saleScenario: z.object({
                corporate: z.boolean(),
                distribution: z.boolean(),
                member: z.boolean(),
                package: z.boolean(),
              }),
              status: z.string(),
            }),
          ),
          roomName: z.string(),
        }),
      )
      .optional(),
    score: z.number().optional(),
    status: z.string().optional(),
  }),
  rooms: z.string(),
  sessionId: z.string(),
  suppliers: z.string(),
  timeout: z.string(),
  userIp: z.string(),
});

const displayFieldsSchema = z.object({
  checkInInstructions: z.string().nullish(),
  feesMandatory: z.string().nullish(),
  feesOptional: z.string().nullish(),
  knowBeforeYouGo: z.string().nullish(),
  specialCheckInInstructions: z.string().nullish(),
  specialInstructions: z.string().nullish(),
  hotelFees: z.array(z.unknown()).nullish(),
});

const roomSchema = z.object({
  amenities: z.array(z.string()),
  cancelPenalties: z
    .array(
      z.object({
        currency: z.string(),
        end: z.string(),
        percent: z.string(),
        start: z.string(),
      }),
    )
    .optional(),
  description: z.string(),
  freeCancellation: z.boolean(),
  images: z.array(
    z.object({
      heroImage: z.boolean().optional(),
      highResolutionUrl: z.string().optional(),
      url: z.string(),
    }),
  ),
  key: z.string(),
  longDescription: z.string().nullish(),
  nonrefundableDateRanges: z.string().nullish(),
  pointOfSale: z.string().nullish(),
  priceCheckHref: z.string().optional(),
  rate: z.union([z.string(), z.number()]),
  rateKey: z.string(),
  saleScenario: z
    .object({
      corporate: z.boolean(),
      distribution: z.boolean(),
      member: z.boolean(),
      package: z.boolean(),
    })
    .optional(),
  taxAmount: z.string(),
  taxExclusiveRate: z.string(),
  type: z.string().nullish(),
});

const travelRulesSchema = z.object({
  remarks: z.string().nullish(),
  cancellationPolicies: z
    .array(
      z.object({
        penaltyPercentage: z.number(),
        dateFrom: z.string(),
        dateTo: z.string(),
      }),
    )
    .nullish(),
  supplierInformation: z
    .object({
      rawRate: z.object({
        roomRate: z.number(),
        currency: z.string(),
      }),
      rawClauses: z.array(
        z.object({
          amount: z.union([z.string(), z.number()]),
          from: z.string(),
        }),
      ),
    })
    .nullish(),
});

export const hotelBookingTransactionSchema = z.object({
  uid: z.string(),
  bookingData: z.object({
    userEmail: z.string(),
    userFirstName: z.string(),
    userLastName: z.string(),
    phoneNumber: z.string(),
    salutation: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    guestSpecialRequests: z.string().nullish(), // can be empty if nothing is entered
    bookingKey: z.string(),
    travelType: z.string(),
    hotelId: z.string(),
    destinationId: z.string(),
    checkInDate: z.string(),
    checkOutDate: z.string(),
    roomCount: z.string(),
    adultCount: z.string(),
    guests: z.string(),
    roomDescription: z.string(),
    alipayHotel: z.string(),
    hotelName: z.string(),
    partnerId: z.string(),
    page: z.string(),
    productType: z.string(),
    currency: z.string(),
    paymentChannel: z.string(),
    payWithPointsTier: z.number(),
    countryName: z.null(),
    locale: z.string(),
    pointsPercentage: z.number(),
    cashPercentage: z.number(),
  }),
  ancillaries: z.object({}),
  status: z.string(),
  processingData: z.object({
    travelCost: z.null(),
    search: searchSchema,
    travelItem: roomSchema.extend({
      displayFields: displayFieldsSchema,
      food: z.number(),
      hotel: z.object({
        data: z.object({
          completed: z.boolean(),
          rooms: z.array(roomSchema),
          search: searchSchema,
          source: z.string(),
          id: z.string(),
          originalCurrency: z.string(),
          cachedMarkup: z.number(),
        }),
        rooms: z.array(
          z.object({
            data: roomSchema.extend({
              displayFields: displayFieldsSchema,
              food: z.number(),
              originalCurrency: z.string(),
            }),
            marketRates: z.object({
              rates: z.array(z.unknown()),
              originalCurrency: z.string(),
              ratesInUsd: z.array(z.unknown()),
              cheapestRate: z.number(),
            }),
          }),
        ),
      }),
      roomDescription: z.string(),
      roomNormalizedDescription: z.string(),
      roomAdditionalInfo: z.object({
        breakfastInfo: z.string(),
        displayFields: displayFieldsSchema.extend({
          surcharges: z.array(
            z.object({ type: z.string(), amount: z.number() }),
          ),
          kaligoServiceFee: z.number(),
        }),
      }),
      roomMetadata: z.object({
        breakfastInfo: z.number(),
        rawDescription: z.string(),
        partnerType: z.null(),
        displayFields: displayFieldsSchema.extend({
          surcharges: z.array(
            z.object({ type: z.string(), amount: z.number() }),
          ),
          kaligoServiceFee: z.number(),
        }),
      }),
      cheapestRoom: z.boolean(),
      source: z.string(),
      margin: z.number(),
      originalMargin: z.number(),
      compRate: z.number(),
      priceType: z.string(),
      chargeableRate: z.number(),
      maxCashPayment: z.number(),
      maxPointsPayment: z.number(),
      maxCashPaymentInUsd: z.number(),
      cost: z.number(),
      costInUsd: z.number(),
      exchangeRate: z.number(),
      costPerPoint: z.number(),
      markup: z.number(),
      clientMarginInUsd: z.number(),
      ascendaMarginInUsd: z.number(),
      tiers: z.array(
        z.object({
          tier: z.number(),
          cashPayment: z.number(),
          cashPaymentInUsd: z.number(),
          pointsPayment: z.number(),
          costToPartner: z.number(),
          costToPartnerInUsd: z.number(),
          points: z.number(),
        }),
      ),
    }),
    travelRules: travelRulesSchema.nullish(),
    paymentData: z.object({}),
    paymentTransaction: z.null(),
    supplierData: z.null(),
    booking: z.null(),
    verificationRequired: z.boolean(),
    redemptionTransaction: z.null(),
    cashOnlyBooking: z.null(),
    bonuses: z.null(),
    supplierPreBooking: z.string().nullish(),
    flightAncillaries: z.null(),
    nnPaymentData: z.null(),
    clientOrderReference: z.null(),
  }),
});

export type HotelBookingTransaction = z.infer<
  typeof hotelBookingTransactionSchema
>;

export const hotelBookingTransactionResponseSchema = z.object({
  bookingTransaction: hotelBookingTransactionSchema,
  errors: z.array(travelBookingTransactionErrorSchema).optional().nullable(),
});

export type HotelBookingTransactionResponse = z.infer<
  typeof hotelBookingTransactionResponseSchema
>;

export type HotelBookingTransactionErrorSchema = z.infer<
  typeof travelBookingTransactionErrorSchema
>;
