import { Input, Label } from '@/design-system-components/input';

import { CheckboxGroup } from '@/design-system-components/checkbox-group/checkbox-group';
import { Checkbox } from '@/design-system-components/checkbox/checkbox';
import {
  RadioPills,
  RoundedRadioPillItem,
} from '@/design-system-components/radio-pill/radio-pill';
import { FlightCheckoutFormSchema } from '@/features/checkout/forms/flights-validation-schema';
import { cn } from '@/utils/tailwind';
import { useTranslations } from 'next-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlModel } from './custom-params';
import { ValueSelectFormControl } from './value-select-form-control';

interface DynamicFormProps {
  controls: FormControlModel[];
  labelClassName?: string;
  field?: 'primaryPassenger' | `secondaryPassengers.${number}`;
}

type FieldNameType =
  | `ancillaries.customParams.${string}`
  | `primaryPassenger.passengerAncillaries.customParams.${string}`
  | `secondaryPassengers.${number}.passengerAncillaries.customParams.${string}`;

export function formatInputName(fieldName: string): string {
  return fieldName
    .replace(/^[A-Z]/, (match) => match.toLowerCase()) // Lowercase the first character
    .replace(/([A-Z])([A-Z][a-z])/g, (match, p1, p2) => p1.toLowerCase() + p2)
    .replace(
      /([a-z])([A-Z]+)/g,
      (match, p1, p2) =>
        p1 + p2.charAt(0).toUpperCase() + p2.slice(1).toLowerCase(),
    );
}

function formatFieldLabel(fieldName: string): string {
  return fieldName.replace(/([A-Z])/g, ' $1').trim();
}

export const AncillariesDynamicForm = ({
  controls,
  labelClassName,
  field,
}: DynamicFormProps) => {
  const t = useTranslations('checkoutErrors');
  const tShared = useTranslations('travelCheckout');
  const {
    register,
    control: formControl,
    formState: { errors },
  } = useFormContext<FlightCheckoutFormSchema>();

  const nonOptionalFields = controls.filter((control) => control.required);

  const renderForm = (control: FormControlModel, index: number) => {
    const controlName = formatInputName(control.id);
    const fieldName: FieldNameType = field
      ? `${field}.passengerAncillaries.customParams.${controlName}`
      : `ancillaries.customParams.${controlName}`;

    const getAncillaryErrors = () => {
      if (!field) return;

      if (field === 'primaryPassenger') {
        return errors.primaryPassenger?.passengerAncillaries?.customParams;
      }

      const [_parentField, formIndex] = field.split('.');
      return errors.secondaryPassengers?.[Number(formIndex)]
        ?.passengerAncillaries?.customParams;
    };

    const formErrors = field
      ? getAncillaryErrors()?.[controlName]
      : errors.ancillaries?.customParams?.[controlName];

    switch (control.type) {
      case 'value_select': {
        return (
          <Controller
            name={fieldName}
            control={formControl}
            key={`${control.id}-${index}`}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <ValueSelectFormControl
                    control={control}
                    value={value ?? undefined}
                    onValueChange={onChange}
                    labelClassName={labelClassName}
                  />
                  {formErrors && (
                    <p className="text-error text-hint">
                      {t('isRequiredError_field', {
                        field: formatFieldLabel(control.label),
                      })}
                    </p>
                  )}
                </>
              );
            }}
          />
        );
      }
      case 'boolean': {
        const pillClassName = cn(
          'h-[58px] flex-1 rounded-lg text-left',
          'bg-primary-100',
          'data-[state=checked]:bg-primary-200 data-[state=checked]:text-primary',
          'data-[state=checked]:hover:bg-primary-200 data-[state=checked]:hover:text-primary',
          'data-[state=checked]:focus:ring-0',
        );
        return (
          <Controller
            name={fieldName}
            control={formControl}
            key={`${control.id}-${index}`}
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className="mr-1 flex flex-auto flex-col gap-3 lg:flex-grow"
                  key={control.id}
                >
                  <Label htmlFor={control.id} className={labelClassName}>
                    <p>{formatFieldLabel(control.label)}</p>
                  </Label>
                  <div>
                    <RadioPills
                      id={control.id}
                      ariaLabel={control.label}
                      className="inline-flex w-full"
                      defaultValue={value}
                      onChange={onChange}
                    >
                      <RoundedRadioPillItem
                        className={pillClassName}
                        key="true"
                        value="y"
                      >
                        {tShared('yes')}
                      </RoundedRadioPillItem>
                      <RoundedRadioPillItem
                        className={pillClassName}
                        key="false"
                        value="n"
                      >
                        {tShared('no')}
                      </RoundedRadioPillItem>
                    </RadioPills>
                  </div>
                  {formErrors && (
                    <p className="text-error text-hint">
                      {t('isRequiredError_field', {
                        field: formatFieldLabel(control.label),
                      })}
                    </p>
                  )}
                </div>
              );
            }}
          />
        );
      }

      case 'multi_select': {
        return (
          <Controller
            name={fieldName}
            control={formControl}
            key={`${control.id}-${index}`}
            render={({ field: { onChange, value } }) => {
              const formValue = value ? value.split(', ') : [];
              return (
                <div
                  className="relative flex flex-auto flex-col gap-3 lg:flex-grow"
                  key={control.id}
                >
                  <Label htmlFor={control.id} className={labelClassName}>
                    <p>{formatFieldLabel(control.label)}</p>
                  </Label>
                  <CheckboxGroup
                    className="flex flex-col gap-3"
                    value={formValue || []}
                    onChange={(value) => {
                      onChange(value.join(', '));
                    }}
                  >
                    {control.options.map((option) => (
                      <Checkbox
                        key={option.value}
                        value={option.value}
                        aria-label={option.text}
                        className="text-base font-normal"
                      >
                        {option.text}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                  {formErrors && (
                    <p className="text-error text-hint">
                      {t('isRequiredError_field', {
                        field: formatFieldLabel(control.label),
                      })}
                    </p>
                  )}
                </div>
              );
            }}
          />
        );
      }

      case 'text': {
        return (
          <div
            className="relative flex-auto lg:flex-grow"
            key={`${control.id}-${index}`}
          >
            <Label htmlFor={control.id} className={labelClassName}>
              <p>{formatFieldLabel(control.label)} </p>
            </Label>
            <Input
              {...register(`${fieldName}`)}
              id={control.id}
              isError={!!formErrors}
            />
            {formErrors && (
              <p className="text-error text-hint">
                {t('isRequiredError_field', {
                  field: formatFieldLabel(control.label),
                })}
              </p>
            )}
          </div>
        );
      }
    }
  };

  return (
    <>{nonOptionalFields.map((control, index) => renderForm(control, index))}</>
  );
};
