import DsSelect, {
  SelectItem,
} from '@/design-system-components/ds-select/select';
import { Label } from '@/design-system-components/input';
import { cn } from '@/utils/tailwind';
import { formatFieldName } from './ancillary-utils';
import { ValueSelectControlModel } from './custom-params';

interface ValueSelectFormControlProps {
  control: ValueSelectControlModel;
  onValueChange: (value: string) => void;
  value: string | undefined;
  labelClassName?: string;
}
export const ValueSelectFormControl = ({
  control,
  onValueChange,
  value,
  labelClassName,
}: ValueSelectFormControlProps) => {
  return (
    <div className="relative flex flex-col gap-3 lg:flex-grow">
      <Label htmlFor={control.id} className={labelClassName}>
        <p>{formatFieldName(control.label)}</p>
      </Label>
      <DsSelect
        id={control.id}
        onSelectionChange={(key) => onValueChange(key.toString())}
        selectedKey={value}
        hideScrollBar={true}
        listBoxClassName="p-0"
        placeholderClassName="data-[placeholder=true]:text-neutral-500"
        popOverClassName="px-0"
      >
        {control.options.map((option) => (
          <SelectItem
            key={option.value}
            id={option.value}
            listBoxClassName={cn(
              'rounded-none focus:outline-0 lg:my-0',
              'data-[state=selected]:bg-primary-200 data-[state=selected]:text-secondary',
            )}
          >
            {option.text}
          </SelectItem>
        ))}
      </DsSelect>
    </div>
  );
};
