export function roundToNearestDenomination(
  value: number,
  denomination: number
) {
  const remainder = value % denomination;
  const shouldRound = denomination / remainder < 2;
  if (shouldRound) {
    return value + (denomination - remainder);
  } else {
    return value - remainder;
  }
}

export function secondsToDays(seconds: number | undefined) {
  if (!seconds) return 0;
  return Math.ceil(seconds / (24 * 60 * 60));
}

export function roundNumber(value: number, decimals: number = 2): number {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
}
