import { z } from 'zod';

import { passengerInformationFormSchema } from '@/features/checkout/forms/flights-validation-schema';

import { travelTierSchema } from '@/schema/travel/tier.schema';
import { travelBookingTransactionErrorSchema } from '@/schema/travel/travel-booking-transaction-error.schema';
import {
  flightItinerarySchema,
  flightSegmentSchemaBookingTransaction,
  flightsSearchSchema,
} from '../prices/prices.schema';

const freeformPriceExpiredErrorMetadataSchema = z.object({
  old: z.object({
    maxCashPayment: z.number(),
    maxPointsPayment: z.number(),
    tier: travelTierSchema,
  }),
  new: z.object({
    maxCashPayment: z.number(),
    maxPointsPayment: z.number(),
    tier: travelTierSchema,
  }),
});

export type FreeformPriceExpiredErrorMetadata = z.infer<
  typeof freeformPriceExpiredErrorMetadataSchema
>;

const priceExpiredErrorMetadataSchema = z.object({
  oldTier: travelTierSchema,
  newTier: travelTierSchema,
});

export type PriceExpiredErrorMetadata = z.infer<
  typeof priceExpiredErrorMetadataSchema
>;

export const flightBookingTransactionTravelItemConditionSchema = z.object({
  allowed: z.boolean(),
  penaltyAmount: z.string().nullable(),
  penaltyCurrency: z.string().nullable(),
});

export const flightBookingTransactionTravelItemSchema =
  flightItinerarySchema.extend({
    conditions: z.object({
      changeBeforeDeparture: flightBookingTransactionTravelItemConditionSchema,
      refundBeforeDeparture: flightBookingTransactionTravelItemConditionSchema,
    }),
    documentsRequired: z.boolean(),
    expiresAt: z.string(),
    passengers: z.array(
      z.object({
        age: z.null(),
        familyName: z.null(),
        givenName: z.null(),
        id: z.string(),
        loyaltyProgrammeAccounts: z.array(z.unknown()),
        type: z.string(),
      }),
    ),
    rate: z.number(),
    rawRate: z.string(),
    requiredFields: z.array(z.unknown()),
    slices: z.array(
      z.object({
        conditions: z.object({
          changeBeforeDeparture:
            flightBookingTransactionTravelItemConditionSchema,
        }),
        fareBrandName: z.string(),
        fromAirport: z.string(),
        toAirport: z.string(),
      }),
    ),
    tax: z.number(),
    oldRate: z.number(),
    segments: z.array(flightSegmentSchemaBookingTransaction).nonempty(),
  });

export const flightBookingTransactionSearchSchema = flightsSearchSchema.extend({
  productType: z.null(),
  couponCode: z.null(),
  partnerId: z.string(),
  searchMethod: z.string(),
  tierId: z.null(),
});

export const flightBookingTransactionSchema = z.object({
  uid: z.string(),
  bookingData: z.object({
    bookingKey: z.string(),
    travelType: z.string(),
    tierId: z.number().nullish(),
    user: z
      .object({
        firstName: z.string(),
        lastName: z.string(),
        phone: z.string(),
        email: z.string(),
      })
      .optional(),
    passengers: z.array(passengerInformationFormSchema),
    partnerId: z.string(),
    page: z.string(),
    currency: z.string(),
    productType: z.string(),
    paymentChannel: z.string(),
    payWithPointsTier: z.number(),
    userEmail: z.string(),
    userFirstName: z.string(),
    userLastName: z.string(),
    phoneNumber: z.string(),
    countryName: z.null(),
    locale: z.string(),
    pointsPercentage: z.number(),
    cashPercentage: z.number(),
  }),
  ancillaries: z.object({}),
  status: z.enum([
    'in_progress',
    'payment_not_started',
    'fail',
    'requires_user_action',
  ]),
  userInfo: z
    .object({
      userId: z.number(),
      userIp: z.string(),
      tenantId: z.string(),
      sessionId: z.null(),
      userCountry: z.string(),
      userCity: z.string(),
      userCfCountry: z.null(),
      countryCode: z.string(),
      isMobileApp: z.null(),
      apiVersion: z.string(),
      applicationType: z.null(),
      gulfResidency: z.null(),
      host: z.null(),
      impersonated: z.null(),
      userAgent: z.string(),
    })
    .optional(),
  processingData: z.object({
    travelCost: z.null(),
    search: flightBookingTransactionSearchSchema,
    travelItem: flightBookingTransactionTravelItemSchema,
    paymentData: z.object({}),
    paymentTransaction: z.null(),
    supplierData: z.null(),
    booking: z.null(),
    verificationRequired: z.boolean(),
    redemptionTransaction: z.null(),
    cashOnlyBooking: z.null(),
    bonuses: z.null(),
    supplierPreBooking: z.object({}).optional(),
    flightAncillaries: z.object({}),
    nnPaymentData: z.null(),
  }),
});

export const flightBookingTransactionResponseSchema = z.object({
  bookingTransaction: flightBookingTransactionSchema,
  errors: z.array(travelBookingTransactionErrorSchema).optional().nullable(),
});

export type FlightBookingTransactionResponse = z.infer<
  typeof flightBookingTransactionResponseSchema
>;

export type FlightBookingTransactionSearch = z.infer<
  typeof flightBookingTransactionSearchSchema
>;

export type FlightBookingTransactionError = z.infer<
  typeof travelBookingTransactionErrorSchema
>;

export type FlightBookingTransactionErrors = {
  errors: FlightBookingTransactionError[];
};

export type FlightBookingTransaction = z.infer<
  typeof flightBookingTransactionSchema
>;
