import { z } from 'zod';

export enum FeeDetailEnum {
  // Expedia
  ExtraPersonFee = 'extra_person_fee',
  Compensation = 'compensation',
  Adjustment = 'adjustment',
  SalesTax = 'sales_tax',
  PropertyFee = 'property_fee',
  // Hotelbeds
  Tax = 'tax',
  Fees = 'fees',
  // Expedia & Hotelbeds
  TaxesAndFees = 'taxes_and_fees',
  TaxAndServiceFee = 'tax_and_service_fee',
}

const feeDetailIdSchema = z.nativeEnum(FeeDetailEnum);
export type FeeDetailId = z.infer<typeof feeDetailIdSchema>;

export const feeDetailSchema = z.object({
  id: feeDetailIdSchema,
  amount: z.number(),
});

export type FeeDetail = z.infer<typeof feeDetailSchema>;

export const priceBreakdownSchema = z.object({
  baseRate: z.number(),
  includedTaxesAndFees: z.array(feeDetailSchema),
  includedTaxesAndFeesTotal: z.number(),
  excludedTaxesAndFeesTotal: z.number(),
  excludedTaxesAndFeesTotalInCurrency: z.number(),
});

export type PriceBreakdown = z.infer<typeof priceBreakdownSchema>;
