import { useFlightDepartureTimeStore } from '@/hooks/flights/use-flight-departure-time-store';

export const isDateNotBeforeToday = (value: string) => {
  const inputDate = new Date(value);
  const today = new Date();
  return inputDate >= today;
};

/**
 * This function is specific to the flight validation schema.
 * If a person is 12 and the birthday has passed, the function will return 13 although the person is considered 12.
 */
export const calculateAgeBasedOnDepartureTime = (dateOfBirth: string) => {
  const departureTime = useFlightDepartureTimeStore.getState().departureTime;

  if (!departureTime) {
    return -1;
  }

  const birthDate = new Date(dateOfBirth);
  const departureDate = new Date(departureTime);

  const yearsDiff = departureDate.getFullYear() - birthDate.getFullYear();
  const isBirthdayPassed =
    departureDate.getMonth() > birthDate.getMonth() ||
    (departureDate.getMonth() === birthDate.getMonth() &&
      departureDate.getDate() > birthDate.getDate());

  return isBirthdayPassed ? yearsDiff + 1 : yearsDiff;
};
