import * as z from 'zod';

import { generateDefaultPassengerData } from '@/features/flights/components/checkout/flight-checkout-utils';
import {
  calculateAgeBasedOnDepartureTime,
  isDateNotBeforeToday,
} from './flights-validation-schema-utils';
import {
  generateEmailSchema,
  generateNameSchema,
  generatePhoneNumberSchema,
  generateRequiredSchema,
} from './travel-checkout-form-schema-utils';

export const passengerTypeSchema = z.enum(['adult', 'child', 'infant']);
export type PassengerType = z.infer<typeof passengerTypeSchema>;

export const formAncillarySchema = z
  .object({
    name: z.string(),
    value: z.string().optional().nullable(),
    required: z.boolean(),
  })
  .refine(
    (context) => {
      const { value, required } = context;
      if (!required) {
        return true;
      }
      if (value) {
        return value.trim() !== '';
      }
      return false;
    },
    {
      message: 'checkoutFieldErrorGeneric',
      path: ['value'],
    },
  );

export type FormAncillarySchema = z.infer<typeof formAncillarySchema>;

export const ancillariesSchema = z
  .object({
    id: z.string().optional(),
    baggages: z.any().optional(),
    meals: z.any().optional(),
    seat: z.any().optional(),
    lounge: z.any().optional(),
    customParams: z.record(z.string().min(1)).optional(),
    frequentFlyers: z
      .array(
        z.object({
          frequentFlyerType: z.string().optional(),
          frequentFlyerNumber: z.string().optional(),
          frequentFlyerName: z.string().optional(),
        }),
      )
      .optional(),
  })
  .optional();

export type AncillariesSchema = z.infer<typeof ancillariesSchema>;

export const passengerInformationSchema = z.object({
  firstName: generateNameSchema('givenName'),
  lastName: generateNameSchema('surname'),
  salutation: generateRequiredSchema('title'),
  type: passengerTypeSchema,
  dateOfBirth: generateRequiredSchema('dateOfBirth'),
  passportRequired: z.boolean().optional(),
  passportNumber: z.string().optional(),
  passportCountry: z
    .string()
    .nullish()
    .refine(
      (context) => {
        const passportCountry = context;
        if (!passportCountry) {
          return false;
        }
        return true;
      },
      {
        message: 'nationalityRequired',
        path: ['passportCountry'],
      },
    ),
  passportExpiryDate: z.string().optional(),
  passengerAncillaries: ancillariesSchema,
  eticket: z.string().optional(),
});

export const passengerInformationFormSchema = passengerInformationSchema
  .refine(
    (context) => {
      const { dateOfBirth } = context;
      const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
      return ageInYears > 0;
    },
    {
      message: 'dateOfBirthFutureError',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { dateOfBirth, type } = context;
      if (type === 'adult') {
        const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
        return ageInYears >= 12;
      }
      return true;
    },
    {
      message: 'dateOfBirthAdultError',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { dateOfBirth, type } = context;
      if (type === 'child') {
        const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
        return ageInYears > 2 && ageInYears < 12;
      }
      return true;
    },
    {
      message: 'dateOfBirthChildError',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { dateOfBirth, type } = context;
      if (type === 'infant') {
        const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
        return ageInYears <= 2;
      }
      return true;
    },
    {
      message: 'dateOfBirthInfantError',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportNumber } = context;
      if (!passportRequired) {
        return true;
      }
      if (passportNumber) {
        return passportNumber.trim() !== '';
      }
      return false;
    },
    {
      message: 'passportNumberRequired',
      path: ['passportNumber'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportNumber } = context;
      if (passportRequired && passportNumber) {
        return /^[a-zA-Z0-9]+$/.test(passportNumber);
      }
      return true;
    },
    {
      message: 'passportNumberSpecialCharacterError',
      path: ['passportNumber'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportExpiryDate } = context;
      if (passportRequired) {
        return passportExpiryDate ? passportExpiryDate.length > 0 : false;
      }
      return true;
    },
    {
      message: 'passportExpiryRequired',
      path: ['passportExpiryDate'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportExpiryDate } = context;
      if (passportRequired) {
        return passportExpiryDate
          ? isDateNotBeforeToday(passportExpiryDate)
          : false;
      }
      return true;
    },
    {
      message: 'passportExpiryPastError',
      path: ['passportExpiryDate'],
    },
  );

export type PassengerInformationForm = z.infer<
  typeof passengerInformationFormSchema
>;

export const contactCheckoutFormSchema = z.object({
  firstName: generateNameSchema('firstName'),
  lastName: generateNameSchema('lastName'),
  email: generateEmailSchema(),
  countryCode: z.string().optional(),
  phoneNumber: generatePhoneNumberSchema(),
});

export type ContactCheckoutForm = z.infer<typeof contactCheckoutFormSchema>;

export enum FormStepEnum {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export const requiredAncillarySchema = z.object({
  name: z.string(),
  value: z.string(),
});

export const flightCheckoutFormSchema = z.object({
  primaryPassenger: passengerInformationFormSchema,
  secondaryPassengers: z.array(passengerInformationFormSchema).default([]),
  contactInformation: contactCheckoutFormSchema,
  ancillaries: ancillariesSchema, // for perPassenger -> false
});

export type FlightCheckoutFormSchema = z.infer<typeof flightCheckoutFormSchema>;
export type FlightConfirmationSchema = FlightCheckoutFormSchema;
export type SecondaryPassengersForm =
  FlightCheckoutFormSchema['secondaryPassengers'];

export const emptyPassengerInformationForm =
  generateDefaultPassengerData('adult');

export const emptyContactInformationForm: ContactCheckoutForm = {
  countryCode: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};
