import { parsePhoneNumberWithError } from 'libphonenumber-js/max';

export const validatePhoneNumber = (value: string) => {
  try {
    const phoneNumber = parsePhoneNumberWithError(value);
    return phoneNumber.isValid();
  } catch (_error) {
    return false;
  }
};
