import { create } from 'zustand';

export const useFlightDepartureTimeStore = create<{
  departureTime?: string;
  setDepartureTime: (departureTime: string | undefined) => void;
}>((set) => ({
  departureTime: undefined,
  setDepartureTime: (departureTime: string | undefined) =>
    set({ departureTime }),
}));
