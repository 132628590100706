import { travelTierSchema } from '@/schema/travel/tier.schema';
import { z } from 'zod';
import { cancellationPolicyContentSchema } from '../travel/cancellation-policy.schema';

const CarCategoryValues = [
  '12_foot_truck',
  '12_passenger_van',
  '15_passenger_van',
  '20_foot_truck',
  '24_foot_truck',
  '26_foot_truck',
  '50_passenger_coach',
  '5_seat_minivan',
  '7_seat_minivan',
  '9_seat_minivan',
  'cargo_van',
  'compact',
  'compact_elite',
  'convertible',
  'economy',
  'economy_elite',
  'estate',
  'exotic',
  'exotic_suv',
  'four_wheel_drive',
  'fullsize',
  'fullsize_elite',
  'intermediate',
  'intermediate_elite',
  'large_suv',
  'large_truck',
  'luxury',
  'luxury_elite',
  'medium_suv',
  'midsize',
  'mini',
  'minivan',
  'mini_elite',
  'moped',
  'moving_van',
  'oversize',
  'premium',
  'premium_elite',
  'regular',
  'small_medium_truck',
  'small_suv',
  'special',
  'standard',
  'standard_elite',
  'stretch',
  'subcompact',
  'suv',
  'unique',
] as const;

const CarCoverageValues = [
  'additional_driver',
  'delivery_fee',
  'travel_time_fee',
  'unlimited_mileage',
  'vehicle_collection',
  'vehicle_delivery',
  'vehicle_license_fee',
  'vehicle_rental',
  'wait_time',
  'winter_fee',
  'winter_service_charge',
  'younger_driver',
  'young_driver',
  'accident_liability_waiver',
  'accident_protection_insurance',
  'additional_liability_insurance',
  'aer_accident_excess_reduction',
  'aer_accident_excess_reduction_plus',
  'airport_fee',
  'baggage_coverage',
  'breakdown_assistance',
  'collision_damage_waiver',
  'collision_damage_waiver_ldw_combo',
  'collision_damage_waiver_ldw_combo_plus',
  'collision_damage_waiver_no_excess',
  'collision_damage_waiver_plus',
  'collision_damage_waiver_reduced_liability',
  'complete_cover_package',
  'compulsory_insurance',
  'damage_excess_reduction',
  'damage_waiver',
  'damage_waiver_plus',
  'environmental_fee',
  'excess_reimbursement_insurance',
  'full_coverage',
  'glass_tyre_waiver',
  'glass_waiver',
  'insurance',
  'insurance_deductible_waiver',
  'insurance_deductible_waiver_plus',
  'insurance_deductible_waiver_reduced_liability',
  'ldw_deductible_waiver',
  'liability_deductible_coverage',
  'liability_insurance_supplement',
  'limited_mileage',
  'loss_damage_waiver',
  'loss_damage_waiver_reduced_liability',
  'max_cover',
  'mexican_insurance',
  'non_waiverable_responsibility',
  'oneway_rental_fee',
  'other_fees',
  'out_of_hours_fee',
  'partial_coverage',
  'partial_damage_waiver',
  'personal_accident_and_effects_coverage',
  'personal_accident_coverage',
  'personal_accident_insurance',
  'personal_effects_coverage',
  'personal_effects_protection',
  'personal_passenger_protection',
  'personal_property_insurance',
  'protection_package',
  'rental_liability_protection',
  'road_tax',
  'special_coverage',
  'super_cover',
  'super_personal_accidental_and_effects_coverage',
  'super_personal_accident_insurance',
  'super_theft_protection',
  'supplemental_liability_insurance',
  'tax',
  'total_fees_and_charges',
  'theft_protection',
  'theft_protection_waiver',
  'theft_waiver',
  'third_party_coverage',
  'third_party_liability_protection',
  'third_party_plus',
] as const;

const CarExtrasValues = [
  'additional_driver',
  'infant_child_seat',
  'gps',
  'snow_chain_set',
  'child_toddler_seat',
  'booster_seat',
  'wifi_access',
  'ski_rack',
] as const;

export type CarCategory = (typeof CarCategoryValues)[number];

const CarRatingValues = ['excellent', 'fair', 'good', 'very-good'] as const;

export type CarRating = (typeof CarRatingValues)[number];

export const pricedCoverages = z.array(
  z.object({
    type: z.enum(CarCoverageValues),
    currency: z.string().nullable(),
    amount: z.string().nullable(),
  }),
);

export type CarPricedCoverages = z.infer<typeof pricedCoverages>;

export type CarPricedCoverage = z.infer<typeof pricedCoverages>[number];

export const charges = z.array(z.enum(CarCoverageValues));

export type CarCharges = z.infer<typeof charges>;

export const pricedEquips = z
  .array(
    z.object({
      type: z.enum(CarExtrasValues),
      currency: z.string(),
      rate: z.string(),
      taxInclusive: z.string(),
      included: z.boolean(),
    }),
  )
  .nullish();
export type CarPricedEquips = z.infer<typeof pricedEquips>;

export const CarFuelTypes = ['diesel', 'petrol', 'unspecified'] as const;

export type CarFuelType = (typeof CarFuelTypes)[number];

export const CarTransmissions = ['Automatic', 'Manual'] as const;

export type CarTransmission = (typeof CarTransmissions)[number];

export const carSchema = z.object({
  unlimitedDistance: z.boolean(),
  transmissionType: z.enum(CarTransmissions),
  pricedEquips,
  pricedCoverages,
  passengers: z.number(),
  name: z.string(),
  imageUrl: z.string(),
  fuelType: z.enum(CarFuelTypes),
  fuelPolicy: z.string(),
  doors: z.number(),
  rawCancellationPolicy: z.object({
    amount: z.string(),
    currency: z.string(),
    timePeriod: z.string(),
    timeUnit: z.string(),
    type: z.string(),
  }),
  charges,
  cancellationPolicy: cancellationPolicyContentSchema,
  category: z.enum(CarCategoryValues),
  bookingKey: z.string(),
  baggage: z.number(),
  aircon: z.boolean(),
  dropoffSupplierAddress: z.string(),
  dropoffSupplierLng: z.string(),
  dropoffSupplierLat: z.string(),
  supplierName: z.string(),
  supplierImageUrl: z.string(),
  supplierLng: z.string(),
  supplierLat: z.string(),
  supplierAddress: z.string(),
  supplierDistance: z.number(),
  reviewsCount: z.number(),
  rating: z.string(),
  ratingCategory: z.enum(CarRatingValues),
  maxCashPayment: z.number(),
  maxPointsPayment: z.number(),
  tiers: z.array(travelTierSchema),
});

export const carPriceSchema = carSchema.merge(
  z.object({
    baseCashPayment: z.number().nullish(),
    basePointsPayment: z.number().nullish(),
    cashNonFixedDiscount: z.number().nullish(),
    cashFixedDiscount: z.number().nullish(),
    pointsNonFixedDiscount: z.number().nullish(),
    pointsFixedDiscount: z.number().nullish(),
    bonusProgramType: z.string().nullish(),
    hasDiscountForCouponCode: z.boolean().nullish(),
    bonusPrograms: z.array(z.unknown()).nullish(),
  }),
);

export const carArraySchema = z.array(carSchema);

export type Car = z.infer<typeof carSchema>;

export const carResponseSchema = z.object({
  completed: z.boolean(),
  currency: z.string(),
  cars: z.array(carSchema),
});

export const carPriceResponseSchema = z.object({
  completed: z.boolean(),
  currency: z.string(),
  cars: z.array(carPriceSchema),
});

export const carTermsResponseSchema = z.object({
  driversAge: z.array(z.string()),
  driversLicence: z.array(z.string()),
  paymentOptions: z.array(z.string()),
  insuranceCoverage: z.array(z.string()),
  breakdownAssistance: z.array(z.string()),
  fuelPolicy: z.array(z.string()),
  mileagePolicy: z.array(z.string()),
  optionalExtras: z.array(z.string()),
  conditionsOfOptionalExtras: z.array(z.string()),
  additionalDriver: z.array(z.string()),
  travelRestrictions: z.array(z.string()),
  taxRate: z.array(z.string()),
  fees: z.array(z.string()),
  formsOfIds: z.array(z.string()),
  carTypes: z.array(z.string()),
  voucher: z.array(z.string()),
  policies: z.array(z.string()),
});

export type CarPricesSchema = z.infer<typeof carResponseSchema>;
export type CarTermsSchema = z.infer<typeof carTermsResponseSchema>;
