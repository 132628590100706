import { Passengers } from '@/design-system-components/passengers-picker/passengers-picker-fields';
import { AirlineDestinationItemSchema } from '@/features/travel/destination-picker/types';
import {
  FlightCabin,
  FlightType,
  flightCabinLabel,
  flightTypeLabel,
} from '@/schema/flights/prices/prices.schema';
import { pluralize } from '@/translation/translation';

export function serializedPassengersData(passengers: Passengers): string {
  return Object.entries(passengers)
    .map(([_, passengerCount]) => passengerCount)
    .join(',');
}

export function deserializedPassengersData(
  passengers: string | null,
): Passengers | null {
  if (!passengers) return null;

  const [adult, child, infant] = passengers
    .split(',')
    .map((count) => parseInt(count));

  return { adult, child, infant };
}

export function getPassengersCount(passengers: string | null): number {
  const deserializedPassengers = deserializedPassengersData(passengers);

  return (
    (deserializedPassengers?.adult ?? 0) +
    (deserializedPassengers?.child ?? 0) +
    (deserializedPassengers?.infant ?? 0)
  );
}

export function tripTypeDisplayText(flightType: FlightType) {
  return flightTypeLabel[flightType];
}

export function cabinClassDisplayText(cabinClassType: FlightCabin) {
  return flightCabinLabel[cabinClassType];
}

export function passengerDataDisplayText({
  adult,
  child,
  infant,
}: Passengers): string {
  const passengerCount = adult + child + infant;
  return `
    ${passengerCount} ${pluralize(passengerCount, 'passenger', 'passengers')}
    `;
}

export function getAirportNameFromCode(
  airportCode: string,
  items?: AirlineDestinationItemSchema[],
): string {
  return items?.find(({ code }) => airportCode === code)?.displayText ?? '';
}

export function getAirportCodeFromName(
  name: string,
  items?: AirlineDestinationItemSchema[],
): string {
  return items?.find(({ displayText }) => displayText === name)?.code ?? '';
}
